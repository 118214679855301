<template>
  <div>
    <a-modal v-model:visible="visible" title="员工权限" @ok="visible = false" okText="完成" cancelText="取消" :afterClose="closeModal" :cancelButtonProps="{ style: { display: 'none' }}" width="55vw">
      <div class="border">
        <h3>所属用户组：</h3>
        <div style="padding:0px 20px 15px">
          <a-checkbox v-for="item in data2" class="box" :checked="item.checked" @change="onChange($event,item)">
            <a-popover :title="item.name">
              <template #content>
                <p>说明：{{item.name}}</p>
                <p>包含权限：</p>
                <ul class="info">
                  <li>1、医生培训材料学习</li>
                  <li>2、医生培训材料学习</li>
                  <li>3、医生培训材料学习</li>
                  <li>4、医生培训材料学习</li>
                </ul>
              </template>
              {{item.name}}
            </a-popover>
          </a-checkbox>
        </div>
      </div>
      <div class="maxHeight">
        <a-collapse v-model:activeKey="activeKey">
          <a-collapse-panel v-for="item in data " :key="item.name" :header="item.name+'（'+item.count+'）'">
            <template #extra>
              <a-checkbox :checked="checked" @change="onChange($event,item2)">
                <a-popover title="item2.name">
                  <template #content>
                    <p>说明：</p>
                    <p>包含权限：</p>
                    <ul class="info">
                      <li>1、医生培训材料学习</li>
                      <li>2、医生培训材料学习</li>
                      <li>3、医生培训材料学习</li>
                      <li>4、医生培训材料学习</li>
                    </ul>
                  </template>
                </a-popover>
              </a-checkbox>
            </template>
            <div class="checkbox">
              <a-checkbox v-for="item2 in item.subMenus" :checked="item2.checked" @change="onChange($event,item2)">
                <a-popover :title="item2.name">
                  <template #content>
                    <p>说明：{{item2.name}}</p>
                    <p>包含权限：</p>
                    <ul class="info">
                      <li>1、医生培训材料学习</li>
                      <li>2、医生培训材料学习</li>
                      <li>3、医生培训材料学习</li>
                      <li>4、医生培训材料学习</li>
                    </ul>
                  </template>
                  {{item2.name}}
                </a-popover>
              </a-checkbox>
            </div>
          </a-collapse-panel>
        </a-collapse>
      </div>
    </a-modal>

    <linkGroup :record="selectedRow" v-model="visible2" />
  </div>
</template>

<script>
import { Add, Search, Delete } from "/src/api/moduleMenu.js";
import { Search as SearchGroup } from "/src/api/group.js";
import linkGroup from '/src/components/member/linkGroup.vue';
export default {
  components: {
    linkGroup
  },
  data() {
    return {
      activeKey: "",
      visible: this.modelValue,
      visible2: false,
      reload: false,
      clientAppName: "智慧中医",
      selectedRows: {},
      columns: [
        {
          title: "名称",
          key: "name",
          dataIndex: "name",
          width: "75%",
          slots: {
            customRender: "name"
          }
        },
        {
          title: "操作",
          dataIndex: "operation",
          width: "25%",
          slots: {
            customRender: "operation"
          }
        }
      ],
      data: [],
      data2: [],
      labelCol: {
        span: 6,
      },
      wrapperCol: {
        span: 14,
      },
    };
  },
  emits: ['update:modelValue'],
  created() {

  },
  mounted() {
  },
  activated() {
  },
  //获取父元素传值
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
    record: {
      type: Object,
      default: {}
    },
  },
  computed: {
  },
  methods: {
    filter() {
      return true
    },
    async detail() {
      let res = await Search({ moduleId: 1, type: "菜单", clientAppName: "智慧中医" })
      // console.log(res)
      if (res.errCode == 0) {
        res.data.forEach(item => {
          let length = item?.subMenus?.filter(item => item.relationId)?.length
          item.count = length == undefined ? 0 : length
          item?.subMenus?.forEach(item2 => {
            if (item2.relationId) {
              item2.checked = true
            } else {
              item2.checked = false
            }
          })
        })
        this.data = res.data
        if (!this.activeKey) {
          this.activeKey = this.data[0].name
        }

      } else {
        this.$message.error("详情查询失败", function () { });
      }
    },
    async detail2() {
      console.log(this.record);
      let res = await SearchGroup()
      console.log(res);
      res.data.forEach(element => {
        element.checked = false
      });
      this.data2 = res.data
      console.log(this.data);

    },
    linkGroup() {
      this.visible2 = true
      this.selectedRow = { id: this.id }
    },
    async onChange(e, target) {
      if (e.target.checked) {
        await Add({ moduleId: this.record.id, menuId: target.id, menuName: target.name })
        this.detail()
      } else {
        await Delete({ id: target.relationId })
        this.detail()

      }
    },
    closeModal() {
      this.$emit('update:modelValue', false);
      //   if (this.reload) {
      //     this.$emit("reload")
      //   }
    },

  },
  watch: {
    record(val) {
      this.detail()
      this.detail2()
    },
    modelValue(val) {
      this.visible = val
    },
  }
};
</script>

<style scoped>
.head {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
  align-items: center;
}
.head span {
  font-weight: bold;
  margin-right: 10px;
}
.checkbox {
  display: flex;
  flex-wrap: wrap;
}
.checkbox label {
  width: 20%;
  margin: 15px 10px;
}
.maxHeight {
  max-height: 60vh;
  margin-top: 30px;
  overflow-y: auto;
}
.info {
  min-width: 20vw;
}
.box {
  width: 16.6666%;
  margin-left: 0;
  margin-top: 15px;
}
.border {
  border: 1px solid #ccc;
  /* padding: 15px 10px 0 10px; */
}
h3 {
  background-color: rgb(250, 250, 250);
  padding: 10px 20px;
  font-size: 14px;
  border-bottom: 1px solid #ccc;
}
</style>
