<template>
  <div>
    <div>
      <div class="searchArea">
        <!-- <a-range-picker v-model:value="inputs.patroltime" :placeholder="['应巡开始时间','应巡结束时间']" :locale="locale" /> -->
        <a-select v-model:value="inputs.gender" placeholder="职务" allowClear>
          <a-select-option value="医生">医生</a-select-option>
          <a-select-option value="女">女</a-select-option>
        </a-select>
        <a-select v-model:value="inputs.isStandard" placeholder="标准化" allowClear>
          <a-select-option :value="true">标准化</a-select-option>
          <a-select-option :value="false">非标准化</a-select-option>
        </a-select>

        <a-button type="primary" shape="circle" @click="search('')">
          <template #icon>
            <SearchOutlined />
          </template>
        </a-button>
        <a-button style="margin-left: 10px" @click="resetForm">重置</a-button>
        <a-button style="margin-left: 10px" type="primary" @click="visible=true">
          <template #icon>
            <PlusOutlined />
          </template>
          新增员工
        </a-button>
      </div>

      <a-table class="ant-table-striped" :columns="columns" :data-source="data" :rowClassName="(record, index) => (index % 2 === 1 ? 'table-striped' : null)" bordered rowKey="id" :pagination="{ pageSize:$store.state.tableSize ,total,current}" @change="search">

        <template #operation="{ record }">
          <div class="flex_sa">
            <div class="editable-row-operations flex_sa">
              <span>
                <a @click="edit(record)">编辑</a>
              </span>
            </div>
            <div class="editable-row-operations flex_sa">
              <span>
                <a @click="edit2(record.id)">权限修改</a>
              </span>
            </div>
          </div>

        </template>

      </a-table>

    </div>

    <editMember :record="selectedRow" v-model="visible" />
    <editRole :record="selectedRow" v-model="visible2" />

  </div>
</template>
<style scoped>
.online {
  background: #40a9ff;
  border-color: #40a9ff;
}
.offline {
  background: #979596;
  border-color: #979596;
}

:deep(.ant-table-tbody > tr > td, .ant-table-thead > tr > th) {
  padding: 10px 8px;
}
</style>


<script>
import {
  SearchOutlined,
  PlusOutlined,
  CheckCircleFilled,
  WarningFilled,
  FormOutlined
} from "@ant-design/icons-vue";
import { reactive } from "vue";
import { cloneDeep } from "lodash-es";
import locale from "ant-design-vue/es/date-picker/locale/zh_CN";
import { Search, Update, Delete } from "/src/api/syndrome.js";
import editMember from '/src/components/member/editMember.vue';
import editRole from '/src/components/member/editRole.vue';


export default {
  components: {
    SearchOutlined,
    PlusOutlined,
    locale,
    CheckCircleFilled,
    WarningFilled,
    FormOutlined,
    editMember,
    editRole
  },
  name: "症候群管理",
  data() {
    return {
      modalBtn: "",
      modalIndex: "0",
      pageSize: "12",
      visible: false,
      visible2: false,
      visible3: false,
      active: undefined,
      id: undefined,
      total: 0,
      current: 1,
      inputs: {
        name: undefined,
      },
      labelCol: {
        span: 6,
      },
      wrapperCol: {
        span: 14,
      },
      columns: [
        {
          title: "姓名",
          key: "name",
          dataIndex: "name",
          width: "20%",
          slots: {
            customRender: "name",
          }
        },
        {
          title: "手机",
          key: "nameInitial",
          dataIndex: "nameInitial",
          ellipsis: true,
          width: "25%",
          slots: {
            customRender: "nameInitial",
          },
        },
        {
          title: "职位",
          key: "zangfuAffect",
          dataIndex: "zangfuAffect",
          // ellipsis: true,
          width: "20%",
          slots: {
            customRender: "zangfuAffect",
          }
        },
        {
          title: "部门",
          key: "part",
          dataIndex: "part",
          // ellipsis: true,
          width: "15%",
          slots: {
            customRender: "part",
          }
        },

        {
          title: "操作",
          dataIndex: "operation",
          width: "25%",
          slots: {
            customRender: "operation",
          }
        }
      ],
      data: [],
      rowSelection: {
        onChange: (selectedRowKeys, selectedRows) => {
          console.log(
            `selectedRowKeys: ${selectedRowKeys}`,
            "selectedRows: ",
            selectedRows
          );
          this.selectedRows = selectedRows;
        },
        onSelect: (record, selected, selectedRows) => {
          console.log(record, selected, selectedRows);
        },
        onSelectAll: (selected, selectedRows, changeRows) => {
          console.log(selected, selectedRows, changeRows);
        }
      },
      editableData: reactive({}),
      selectedRows: {},
      selectedRow: {},
      // doExcel: doExcel
    };
  },
  created() { },
  mounted() {
    this.search("");
  },
  activated() {

  },
  methods: {
    resetForm() {
      this.inputs = {
        name: undefined,
      }
    },
    async onDelete(id) {
      this.editableData = {}
      let res = await Delete({ id })
      if (res.errCode == 0) {
        this.data = this.data.filter(function (item) {
          return item.id !== id;
        });
      } else {
        this.$message.error("删除失败", function () { });
      }
    },
    edit(record) {
      this.visible = true
      this.selectedRow = { ...record }
    },
    edit2(record) {
      this.visible2 = true
      this.selectedRow = { ...record }
    },
    async save(id) {
      let _data = this.data;
      //判断editableData和修改前是否一致，如果一致则不修改
      if (JSON.stringify(this.data.find((item) => item.id == id)) === JSON.stringify(this.editableData[id])) {
        // console.log("未修改")
        delete this.editableData[id];
        return;
      }
      let res = await Update(this.editableData[id])
      if (res.errCode == 0) {
        Object.assign(
          _data.filter(item => id === item.id)[0],
          this.editableData[id]
        );
        delete this.editableData[id];
      }
    },
    cancel(id) {
      delete this.editableData[id];
    },
    tabsName(tabName) {
      this.$store.commit("changePage", { name: tabName.name, query: tabName.query });
    },
    async search(pagination) {
      let _this = this;
      this.editableData = {}
      let params = {
        name: this.inputs.name,
        nameInitial: this.inputs.nameInitial == undefined ? "" : this.inputs.nameInitial,
        gender: this.inputs.gender == undefined ? "" : this.inputs.gender,
        isStandard: this.inputs.isStandard == undefined ? "" : this.inputs.isStandard,
        pageSize: this.pageSize,
      };
      if (pagination == "") {
        params.current = 1
      } else {
        params.current = pagination.current
        this.current = pagination.current;
      }
      let res = await Search(params)
      if (res.errCode == 0) {
        if (pagination == "") {
          // this.$message.success("查询成功", function () { });
          this.current = 1;
        }
        this.total = res.data.total;
        this.data = res.data.records;
      } else {
        this.$message.error("查询失败", function () { });
      }
      // this.data = res
    },
    async changeIsStandard(record) {
      let res = await Update({ id: record.id, isStandard: !record.isStandard })
      if (res.errCode == 0) {
        record.isStandard = !record.isStandard;
        this.$store.dispatch('getSyndromeList')
      } else {
        this.$message.error("操作失败", function () { });
      }
    },
  }
};
</script>