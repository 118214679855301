<template>
  <div>
    <a-modal v-model:visible="visible" title="关联用户组" @ok="handleOk" okText="确认" cancelText="取消" :afterClose="closeModal" :cancelButtonProps="{ style: { display: 'none' }}">

      <a-form-item label="可关联的用户组">
        
      </a-form-item>

    </a-modal>
  </div>
</template>

<script>
import { reactive } from "vue";
import { Search } from "/src/api/group.js";
export default {
  components: {
  },
  data() {
    return {
      visible: this.modelValue,
      visible2: false,
      reload: false,
      medicineFilter: "",
      roleId: "",
      selectedRows: {},
      columns: [
        {
          title: "名称",
          key: "roleName",
          dataIndex: "roleName",
          width: "75%",
          slots: {
            customRender: "roleName"
          }
        },
        {
          title: "操作",
          dataIndex: "operation",
          width: "25%",
          slots: {
            customRender: "operation"
          }
        }
      ],
      data: [],
      labelCol: {
        span: 6,
      },
      wrapperCol: {
        span: 14,
      },
    };
  },
  emits: ['update:modelValue'],
  created() {

  },
  mounted() {
  },
  activated() {
  },
  //获取父元素传值
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
    record: {
      type: Object,
      default: {}
    },
  },
  computed: {
  },
  methods: {
    filter() {
      return true
    },
    async detail() {
      console.log(this.record);
      let res = await Search()
      console.log(res);
      res.data.forEach(element => {
        element.checked = false
      });
      this.data = res.data
      console.log(this.data);

    },
    async handleOk() {
      this.visible = false
    },
    closeModal() {
      this.$emit('update:modelValue', false);
      //   if (this.reload) {
      //     this.$emit("reload")
      //   }
    },

  },
  watch: {
    record(val) {
      this.detail()
    },
    modelValue(val) {
      this.visible = val
    },
  }
};
</script>

<style scoped>
.head {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
  align-items: center;
}
.head span {
  font-weight: bold;
  margin-right: 10px;
}
.box {
  width: 20%;
  margin-left: 0;
  margin-top: 15px;
}
</style>
